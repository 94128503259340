<script>
export default {
  name: "base-comments-count",

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    },
    linkClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="border-radius border shadow c-tuna py-6 py-md-3 px-6 px-md-4 font-14 font-md-12 fw-600 p-relative">
    <i class="fas fa-comment-alt mr-3" />
    <span>
      {{ ` ${value}` }} <span class="d-none d-md-inline">Comments</span>
    </span>
    <a v-if="linkUrl" :href="linkUrl" :class="linkClass"></a>
  </div>
</template>
<script>

import FooterSocialItem from "~/components/Shared/Footer/FooterSocialItem";

export default {
  name: "footer-social-menu",

  components: { FooterSocialItem }
};
</script>

<template>
  <ul class="d-inline-flex justify-content-between align-items-center list-unstyled my-0">
    <footer-social-item
      class="mr-6 mr-md-4"
      icon-class="fab fa-youtube"
      itemUrl="#"
    />
    <footer-social-item
      class="mr-6 mr-md-4"
      icon-class="fab fa-facebook-f"
      itemUrl="#"
    />
    <footer-social-item
      class="mr-6 mr-md-4"
      icon-class="fab fa-instagram"
      itemUrl="#"
    />
    <footer-social-item
      icon-class="fab fa-facebook-messenger"
      itemUrl="#"
    />
  </ul>
</template>


<script>

import BaseButton from "~/components/Base/BaseButton"
import BaseImgRound from "~/components/Base/BaseImgRound";
import { redirectTo } from "~/utils/genericUtils";
import { logout } from "~/services/api/auth.api";
import { useStore } from "vuex"
import {computed} from "vue";
import BaseLink from "~/components/Base/BaseLink";

export default {
  name: "header-profile-nav",

  components: {
    BaseLink,
    BaseImgRound,
    BaseButton
  },

  setup() {
    const store = useStore()
    const state = computed(() => ({
      avatar: store.getters.userAvatar
    }))
    async function handleLogout() {
      let logoutRes = await logout()

      if (!logoutRes.error) {
        redirectTo('/login')
      }
    }

    return {
      state,
      handleLogout
    }
  }
};
</script>

<template>
  <ul class="profile-nav ml-lg-auto">
    <li>
      <base-link to="/profile">
        <base-button class="circle btn-def bg-transp pa-0 btn-shadow profile-link">
          <base-img-round :src="state.avatar"/>
        </base-button>
      </base-link>
    </li>
    <li>
      <div class="p-relative">
        <base-button class="btn-def bg-transp btn-c-blue-bayoux font-18 profile-link">
          <i class="fas fa-comment"></i>
        </base-button>
      </div>
    </li>
    <li>
      <base-button class="btn-def bg-transp btn-c-blue-bayoux font-18 profile-link">
        <i class="fas fa-headset"></i>
      </base-button>
    </li>
    <li>
      <base-button
        class="btn-def bg-transp btn-c-blue-bayoux font-18 profile-link"
        @click="handleLogout()"
      >
        <i class="fas fa-sign-out-alt"></i>
      </base-button>
    </li>
  </ul>
</template>


<script>
import BaseImgRound from "~/components/Base/BaseImgRound";
export default {
  name: "base-comment-input",
  components: {
    BaseImgRound
  },
  props: {
    textareaId: {
      type: String,
      required: true
    },
    placeholderText: {
      type: String,
      default: ''
    },
    textareaClass: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: "default"
    },
    avatarClass: {
      type: String,
      default: ''
    }
  },

  setup() {
    const typeClasses = {
      default: {
        label: "border-radius-xl bg-wh border pa-4 pa-lg-10 d-block mb-12 shadow textarea-label-type2",
        img: "circle img-object-fit w-14 h-14 w-md-16 h-md-16 border-white-2x shadow_darker_10 p-relative z-index-1 mr-4"
      },
      footer: {
        label: "border-radius-bottom-xl bg-wh border-top pa-6 pa-lg-10 mb-0 d-block textarea-label",
        img: "circle img-object-fit w-14 h-14 w-md-16 h-md-16 border-white-2x shadow_darker_10 mr-4"
      }
    }

    return {
      typeClasses
    }
  }

}
</script>

<template>
  <label
    :for="textareaId"
    :class="typeClasses[type].label"
  >
    <div class="d-grid grid_auto_1fr_auto align-items-start">
      <div>
        <base-img-round :content-class="avatarClass"/>
      </div>
      <div class="form-group mb-0 p-relative">
        <textarea
          class="form-control border-0 shadow-0 font-12 font-md-14 px-2 px-md-4"
          :class="textareaClass"
          :placeholder="placeholderText"
          :id="textareaId"
        ></textarea>
      </div>
      <button
        type="button"
        class="align-self-center btn-primary circle w-12 h-12 w-md-14 h-md-14 shadow d-flex align-items-center justify-content-center font-14 border-0 z-index-1 ml-4"
      >
        <i class="fas fa-angle-right" />
      </button>
    </div>
  </label>
</template>
<script>


import RatingStars from "~/components/Shared/RatingStars";
export default {
  name: "component-rating",
  components: {
    RatingStars

  },
  props: {
    rating: {
      type: Number,
      default: 0
    }
  }
}
</script>

<template>
  <div class="border-radius shadow border mt-8">
    <div class="border-radius py-6 px-6 bg-whisper">
      <div class="d-block d-md-flex align-items-center justify-content-between">
        <div class="text-uppercase c-cloud-burst font-13 fw-600 mb-4 mb-md-0 text-center text-md-left">
          This component's rating is:
          <span class="ml-4 c-primary">3.5 / 5</span>
        </div>
        <rating-stars :rating="rating" item-class="mr-3"/>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from  "vue"

export default {
  name: "popup-component",

  props: {
    hideOnClickOutside: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    let show = ref(true)

    function toggleShow() {
      if (!props.hideOnClickOutside) {
        return
      }
      emit('update:modelValue', !props.modelValue)
    }

    return {
      show,
      toggleShow
    }
  }
}
</script>

<template>
  <div
    v-show="show"
    class="popup-component-wrapper"
    @click="toggleShow()"
  >
    <slot></slot>
  </div>
</template>

<style>
  .popup-component-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

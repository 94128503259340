<script>
export default {
  name: "card-body"
};
</script>

<template>
  <div class="bg-wh">
    <slot/>
  </div>
</template>


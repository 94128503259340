import axios from "axios"

const RequestService = {
  init() {
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    axios.defaults.withCredentials = true
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      return error.response;
    });
  },

  get(resource) {
    return axios.get(resource)
  },

  post(resource, data, headers = {}) {
    return axios.post(resource, data, headers)
  },

  put(resource, data) {
    console.log("PUT Data: ", data)
    return axios.put(resource, data)
  },

  delete(resource) {
    return axios.delete(resource)
  },
}

export default RequestService

<script>
export default {
  name: "BikeDetailsItem",

  props: {
    value: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <li>
    <span class="c-primary">{{ value }}</span>
    {{ unit }}
  </li>
</template>
<script>

import BaseTitle from "~/components/Base/BaseTitle"

export default {
  name: "base-title-wrapper",

  components: {BaseTitle},

  props: {
    titleText: {
      type: String,
      default: ''
    }
  }
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-between height-button-ns mb-8">
    <slot>
      <base-title :text="titleText"/>
    </slot>
  </div>
</template>


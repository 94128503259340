<script>
export default {
  name: "main-logo",
};
</script>

<template>
  <a href="/" class="navbar-brand">
    <img
      alt="image"
      src="/img/logo.svg"
      class="h-auto w-22 w-md-23"
    >
  </a>
</template>


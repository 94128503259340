import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist'
import {getProfile} from "~/services/api/profile.api";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const DUMMY_AVATAR = '/img/dummy/woman_photo.jpg'

const state = {
    user: {
      userName: '',
      email: '',
      firstName: '',
      lastName: '',
      state: '',
      city: '',
      zip: '',
      bio: '',
      avatar: ''
    }
}

const mutations = {
  MERGE_USER(state, newUser) {
    Object.keys(newUser).forEach( k => {
      state.user[k] = newUser[k]
    })
  },
}

const getters = {
  user: state => ({
    ...state.user,
    avatar: state.user.avatar || DUMMY_AVATAR
  }),

  userFullName: state => `${state.user.firstName} ${state.user.lastName}`,

  userAvatar: state => state.user.avatar || DUMMY_AVATAR,

  userBioSnippet: state => {
    const SNIPPET_LENGTH = 50
    const bio = state.user.bio || ''

    return bio.length > SNIPPET_LENGTH ? `${bio.slice(0, SNIPPET_LENGTH - 3)}...` : bio
  }
}

const actions = {
  getProfile: async ({ commit }) => {
    const { data, error } = await getProfile()

    if (!error) {
      commit('MERGE_USER', {
        ...data,
        avatar: data.avatar || DUMMY_AVATAR
      })
    }

    return { data, error }
  }
}

export default createStore({
  state,
  mutations,
  getters,
  actions,
  plugins: [vuexLocal.plugin]
})

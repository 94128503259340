<script>
import BaseLink from "~/components/Base/BaseLink";
import BaseImgRound from "~/components/Base/BaseImgRound";

export default {
  name: "contact-item",

  components: {
    BaseImgRound,
    BaseLink
  },

  props: {
    avatarType: {
      type: String,
      default: 'img'
    },
    withBubble: {
      type: Boolean,
      default: true
    },
    itemClass: {
      type: String,
      default: 'd-flex align-items-center no_underline c-cloud-burst mb-8'
    },
    textClass: {
      type: String,
      default: 'd-block font-15 c-cloud-burst'
    },
    text: {
      type: String,
      default: 'Darrell Chavez'
    }
  }
}
</script>

<template>
  <base-link
    to="#"
    :class="itemClass"
  >
    <span class="d-grid grid_auto_1fr grid-col-gap-1rem align-items-center">
      <base-img-round
        :with-bubble="withBubble"
        bubble-type="online"
        :type="avatarType"
      />
      <span>
        <span :class="textClass">
          {{ text }}
        </span>
      </span>
    </span>
  </base-link>
</template>
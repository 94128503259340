export const redirectTo = (route) => {
  window.location.href = route
}

export const getMainPage = () => {
  let location = window.location,
    origin = location.origin,
    href = location.href,
    relevant = href.replace(origin, '')

  return  relevant.split('/').filter(s => s)[0]
}

export const toFormData = data => {
  const formData = new FormData()

  Object.keys(data).forEach( k => {
    formData.append(k, data[k])
  })

  return formData
}

export const objChangedShallow = (oldState, newState) => {
  let changed = false

  const keys = Object.keys(newState)

  for (let i = 0; i < keys.length; i++) {
    const newVal = newState[keys[i]]
    const oldVal = oldState[keys[i]]

    if (newVal !== oldVal) {
      changed = true
      break
    }
  }

  return changed
}

export default {
  redirectTo,
  toFormData,
  objChangedShallow
}

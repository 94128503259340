import api from "~/services/ApiService"

const ENDPOINT = 'auth'

const login = async(email, password) => await api.post(`${ENDPOINT}/login`, { email, password })

const logout = async() => await api.get(`${ENDPOINT}/logout`)

const register = async(data) => await api.post(`${ENDPOINT}/register`, data)

export {
  login,
  logout,
  register
}

<script>
export default {
  name: "footer-social-item",

  props: {
    iconClass: {
      type: String,
      default: ''
    },
    itemUrl: {
      type: String,
      default: '#'
    }
  }
};
</script>

<template>
  <li>
    <a
      :href="itemUrl"
      target="_blank"
      class="btn-gray-chateau font-20 font-md-16 w-16 h-16 w-md-13 h-md-13 circle d-flex align-items-center justify-content-center pa-0"
    >
      <i :class="iconClass" aria-hidden="true"></i>
    </a>
  </li>
</template>


import api from "~/services/ApiService"

const ENDPOINT = 'profile'

const getProfile = async () => await api.get(`${ENDPOINT}`)

const updateProfile = async(data) => await api.put(`${ENDPOINT}`, data)

export {
  getProfile,
  updateProfile
}

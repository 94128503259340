export default {
  ERROR: {
    GENERIC: 'An error occurred'
  },

  SUCCESS: {
    REGISTER: 'Account successfully created',
    PROFILE: 'Profile successfully updated'
  }
}

import RequestService from '~/services/RequestService'
import envConf from "~/env.config"
import {getMainPage, redirectTo} from "~/utils/genericUtils";
import dictionary from "~/utils/dictionaryUtils";

RequestService.init()

const API_BASE = envConf.apiBase
const SUCCESS_CODES = [200, 204]

export const api = {
  async get(resource, urlParams = {}) {
    console.log("Firing get request")
    return await this._executeRequestSafe('get', resource, {}, urlParams)
  },

  async post(resource, params = {}, urlParams = {}) {
    console.log("Firing post request - body: ", params)
    return await this._executeRequestSafe('post', resource, params, urlParams)
  },

  async put(resource, params = {}, urlParams = {}) {
    return await this._executeRequestSafe('put', resource, params, urlParams)
  },

  async delete(resource, urlParams = {}) {
    return await this._executeRequestSafe('post', resource, {}, urlParams)
  },

  _getUrl(resource, params = {}) {
    let paramsUrl = Object.keys(params).map(k => `${k}=${params[k]}`).join('&')
    return `${API_BASE}/${resource}${ paramsUrl ? `?${paramsUrl}` : ''}`
  },

  _getError(status, data) {
    let error = null
    if (this._isErrorCode(status)) {
      error = this._getReqError(data, status)
    }

    return error
  },

  _isErrorCode(status) {
    return SUCCESS_CODES.indexOf(status) === -1
  },

  _getReqError(data, status) {
    return status >= 500 ? dictionary.ERROR.GENERIC : data || dictionary.ERROR.GENERIC
  },

  async _executeRequestSafe(type, resource, params = {}, urlParams = {}) {
    let req = null

    switch (type) {
      case 'get':
        req = RequestService.get(this._getUrl(resource, urlParams))
        break
      case 'post':
        req = RequestService.post(this._getUrl(resource, urlParams), params)
        break;
      case 'put':
        req = RequestService.put(this._getUrl(resource, urlParams), params)
        break
      case 'delete':
        req = RequestService.delete(this._getUrl(resource, urlParams))
        break
    }

    try {
      let reqResponse = await req,
        status = reqResponse.status,
        data = reqResponse.data,
        error = this._getError(status, data)

      if (status === 401 && getMainPage() !== 'login') {
        redirectTo("/login")
      }

      return {
        data,
        error,
        status
      }
    } catch (e) {
      return {
        error: dictionary.ERROR.GENERIC,
        errorDetail: e
      }
    }
  }
}

export default api

<script>

export default {
  name: "base-rating-star",

  props: {
    type: {
      type: String,
      default: "empty"
    }
  },

  setup() {
    const typeClasses = {
      button: {
        full: "p-relative full_star",
        empty: "p-relative",
        half: "p-relative half_star"
      }
    }

    return {
      typeClasses
    }
  }

}
</script>

<template>
  <li>
    <div :class="typeClasses.button[type]"></div>
  </li>
</template>
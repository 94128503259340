<script>
export default {
  name: "card-header",

  props: {
    cardTitle: {
      type: String,
      default: ''
    }
  }
};
</script>

<template>
  <div class="border-radius-top-xl px-6 px-lg-10 fw-600 font-18 font-xl-16 font-xxl-18 c-cloud-burst">
    {{ cardTitle }}
    <slot />
  </div>
</template>

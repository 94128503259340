<script>

export default {
  name: 'base-button',

  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Loading...',
    },
    value: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconClass: {
      type: String,
      default: ''
    },
    includeBaseClasses: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    },
    animate: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const baseClasses = 'btn-primary btn-def border-radius py-5 px-12 px-md-6 px-lg-12 font-12 font-lg-14 text-uppercase btn-shadow d-inline-flex align-items-center justify-content-center'

    function getClasses() {
      const _baseCls = props.includeBaseClasses ? baseClasses : ''
      const _animateCls = props.animate ? 'btn-fade-in' : ''
      return `${_baseCls} ${_animateCls}`
    }

    return {
      baseClasses,
      getClasses
    }
  }
}
</script>

<template>

    <button
      v-if="show"
      :class="getClasses()"
      :disabled="disabled || loading"
    >
      <slot>
        <div v-if="loading" class="loader inverse mr-4">
          <div></div>
        </div>
        <i
          v-if="iconClass"
          :class="iconClass"
        />
        {{ loading ? loadingText : value }}
      </slot>
    </button>
</template>

<style>

  .btn-fade-in {
    animation: fadeInOpacity .7s
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

</style>

<script>
import BaseButton from "~/components/Base/BaseButton";
import BaseImgRound from "~/components/Base/BaseImgRound";
export default {
  name: "comment-section",

  components: {BaseImgRound, BaseButton},

  props: {
    type: {
      type: String,
      default: 'myquiver'
    }
  }
}
</script>

<template>
  <div class="d-grid grid_auto_1fr grid-col-gap-comments align-items-start">
    <div>
<!--      <div class="circle w-14 h-14 w-md-16 h-md-16 border-white-2x shadow_darker_10 d-flex align-items-center justify-content-center c-cinnabar bg-cinnabar-o-2 font-16 font-md-20">-->
<!--        D-->
<!--      </div>-->
      <base-img-round
        type="text"
        text="Daisy"
        content-class="circle w-14 h-14 w-md-16 h-md-16 border-white-2x shadow_darker_10 d-flex align-items-center justify-content-center c-cinnabar bg-cinnabar-o-2 font-16 font-md-20"/>
    </div>
    <div>
      <div class="d-flex align-items-start justify-content-between">
        <div class="font-14 font-lg-15 c-cloud-burst fw-600">
          Daisy Porter
          <div class="font-11 c-gray-chateau d-flex fw-400 align-items-center justify-content-lg-end text-right mt-3">
            <span class="mr-3 d-none d-md-inline-block">
                <i class="far fa-clock" />
            </span>
            10:00AM 03/10/2019
          </div>
        </div>
        <div class="d-inline-flex align-items-center ml-4">
          <base-button class="btn-def bg-transp btn-c-primary font-14 font-lg-12 mr-5 pa-0">
            <i class="fas fa-thumbs-up" />
          </base-button>
          <base-button class="btn-def bg-transp btn-c-amaranth font-14 font-lg-12 pa-0 mr-5 mr-md-10">
            <i class="fas fa-thumbs-down" />
          </base-button>
          <base-button
            v-if="type === 'myquiver'"
            class="btn-linklike-tuna border-0 bg-transp font-14 font-lg-12 pa-0"
          >
            <span class="c-gray-chateau">
              <i class="fab fa-font-awesome-flag" />
            </span>
          </base-button>
          <base-button
            v-else-if="type === 'newsfeed'"
            class="btn-def bg-transp btn-c-blue-bayoux font-18 pa-0"
            icon-class="fas fa-ellipsis-h"
          />

        </div>
      </div>
      <div class="mt-4">
        <p class="font-12 font-lg-14 c-jumbo">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>
      </div>
      <base-button
        type="button"
        class="btn-linklike-tuna border-0 bg-transp font-12 pa-0 text-uppercase"
        icon-class="fas fa-reply mr-4"
      >
        <i class="fas fa-reply mr-4" />Reply
      </base-button>
    </div>
  </div>
</template>
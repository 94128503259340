<script>
export default {
  name: "footer-copyright",
};
</script>

<template>
  <p class="mb-0 c-gray-chateau font-14">
    &copy; Quiver LLC Copyright 2020. All rights reserved.
  </p>
</template>
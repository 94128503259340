<script>

export default {
  name: "base-title",

  props: {
    text: {
      type: String,
      default: ''
    }
  }
};
</script>

<template>
  <h2 class="font-22 font-md-18 font-lg-22 c-shark mb-0">
    {{ text }}
  </h2>
</template>


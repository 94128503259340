<script>

import HeaderMenuItem from "~/components/Shared/Header/HeaderMenuItem";

export default {
  name: "header-menu",

  components: {HeaderMenuItem},

  props: {
    page: {
      type: String,
      default: ''
    }
  }
};
</script>

<template>
  <ul class="navbar-nav mx-auto fw-600">
    <header-menu-item
      class="nav-item mb-3 mb-lg-0"
      text="Home"
      link-url="/"
      link-class="nav-link"
      :active="page.toLowerCase() === 'home'"
    />
    <header-menu-item
      class="nav-item mb-3 mb-lg-0"
      text="My Quiver"
      link-url="/myquiver"
      link-class="nav-link"
      :active="page.toLowerCase() === 'myquiver'"
    />
    <header-menu-item
      class="nav-item mb-3 mb-lg-0"
      text="My profile"
      link-url="/profile"
      link-class="nav-link"
      :active="page.toLowerCase() === 'profile'"
    />
  </ul>
</template>


<script>
import BaseButton from "~/components/Base/BaseButton";

export default {
  name: "base-carousel-indicator",

  components: {
    BaseButton
  },

  props: {
    slideCount: {
      type: Number,
      default: 4
    },
    currentIdx: {
      type: Number,
      default: 0
    },
    indicatorStyle: {
      type: String,
      default: 'default'
    },
    interactive: {
      type: Boolean,
      default: false
    },
    wrapperClasses: {
      type: String,
      default: 'carousel-indicators p-absolute r-0 l-0 b-8 mb-0 carousel-indicators-shadow'
    }
  },

  setup(props, {emit}) {

    function handleIndicatorClick(idx) {
      if (props.interactive) {
        emit('bullet-click', idx - 1)
      }
    }

    return {
      handleIndicatorClick
    }
  }
}
</script>

<template>
  <ol
    v-if="indicatorStyle === 'default'"
    :class="wrapperClasses"
  >
    <li
      v-for="idx in slideCount"
      :key="`slide-indicator-${idx}`"
      class="w-5 h-5 d-flex align-items-center justify-content-center mx-2"
      :class="{ active: idx === currentIdx + 1 }"
      @click="handleIndicatorClick(idx)"
    ></li>
  </ol>

  <ul
    v-else-if="indicatorStyle === 'bike-card'"
    class="card-img-slider list-unstyled d-none d-md-inline-flex align-items-center p-absolute b-4 r-4 z-index-1 mb-0"
  >
    <li
      v-for="idx in slideCount"
      :key="`slide-indicator-${idx}`"
      :class="{'mr-3': idx < slideCount}"
    >
      <base-button
        type="buttons"
        class="btn-card-img-slider btn-def circle pa-0"
        :class="{ active: idx === currentIdx + 1 }"
        @click="handleIndicatorClick(idx)"
      />
    </li>
  </ul>

</template>

<script>
export default {
  name: "card-footer"
};
</script>

<template>
  <div class="bg-wh">
    <slot />
  </div>
</template>


<script>
export default {
  name: 'main-section'
}
</script>

<template>
  <main class="mt-17">
    <slot/>
  </main>
</template>
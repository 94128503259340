<script>
export default {
  name: "header-menu-item",

  props: {
    text: {
      type: String,
      default: ''
    },
    linkClass: {
      type: String,
      default: 'nav-link'
    },
    linkUrl: {
      type: String,
      default: '/'
    },
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>

  <li :class="{ active }">
    <a class="nav-link" :href="linkUrl">
      {{ text }}
    </a>
  </li>

</template>


<script>
export default {
  name: "card-wrapper"
};
</script>

<template>
  <div class="border-radius-xl shadow">
    <slot/>
  </div>
</template>


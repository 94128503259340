<script>
import BaseLikesCount from "~/components/Base/BaseLikesCount";
import BaseViewsCount from "~/components/Base/BaseViewsCount";
import BaseCommentsCount from "~/components/Base/BaseCommentsCount";

export default {
  name: "newsfeed-reactions",

  components: {
    BaseLikesCount,
    BaseCommentsCount,
    BaseViewsCount
  },

  props: {
    reactions: {
      likes: {
        type: Number,
        default: 0
      },
      comments: {
        type: Number,
        default: 0
      },
      views: {
        type: Number,
        default: 0
      }
    }
  }
}
</script>

<template>
  <div class="d-flex align-items-center justify-content-between mt-10">
    <div class="d-flex align-items-center justify-content-start">
      <base-likes-count :likes="reactions.likes" />
      <base-views-count :value="reactions.views" />
    </div>
    <div>
      <base-comments-count :value="reactions.comments" />
    </div>
  </div>
</template>
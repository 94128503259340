<script>

export default {
  name: "carousel-item",

  props: {
    src: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    direction: {
      type: String,
      default: ''
    },
    actionId: {
      type: Number,
      default: null
    },
    imgClass: {
      type: String,
      default: 'd-block w-100 h-100 img-object-fit'
    }
  },
}
</script>


<template>
  <transition :name="`slide-${direction}`">
    <div
      class="carousel-item embed-responsive-item p-relative float-left w-100 d-block"
      v-show="index === currentIndex"
    >
      <img
        :src="src"
        draggable="false"
        class="swipe"
        :class="imgClass"
        alt="..."
      >
    </div>
  </transition>

</template>

<style>

  .slide-next-enter-active {
    animation: nextInAnimation .7s ease-in-out;
  }

  .slide-next-leave-active {
    animation: nextOutAnimation .7s ease-in-out;
  }

  .slide-prev-enter-active {
    animation: prevInAnimation .7s ease-in-out;
  }

  .slide-prev-leave-active {
    animation: prevOutAnimation .7s ease-in-out;
  }

  @keyframes nextInAnimation {
    from { transform: translateX(100%); }
    to {transform: translateX(0%); }
  }

  @keyframes nextOutAnimation {
    to {transform: translateX(-100%); }
  }

  @keyframes prevInAnimation {
    from { transform: translateX(-100%); }
    to {transform: translateX(0%); }
  }

  @keyframes prevOutAnimation {
    to {transform: translateX(100%); }
  }

</style>

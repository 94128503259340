<script>
import ContactItem from "~/components/Shared/ContactItem";
import { ref } from "vue"

export default {
  name: "base-likes-count",

  components: { ContactItem },

  props: {
    likes: {
      type: Array,
      default: () => []
    }
  },

  setup(props) {
    const snippetSize = 5,
      likesSnippet = props.likes.slice(0, snippetSize),
      moreCount = props.likes.length - snippetSize,
      open = ref(false)

    function toggleStatus() {
      open.value = !open.value
    }

    return {
      likesSnippet,
      moreCount,
      open,
      toggleStatus
    }
  }
}
</script>

<template>
  <div
    class="border-radius border shadow c-primary py-6 py-md-3 px-6 px-md-4 font-14 font-md-12 fw-600 mr-8 p-relative cursor-help"
    @click="toggleStatus()"
  >
    <i class="fas fa-thumbs-up mr-3" />
    <span>
      {{ ` ${likes.length}` }} <span class="d-none d-md-inline">Likes</span>
    </span>
    <div
      v-if="open"
      class="tooltip popover tooltip popover popover_width_fluid open" style="position: absolute; bottom: 100%; width: 250px; left: -90px;"
      x-placement="top"
    >
      <div class="wrapper">
        <div class="tooltip-inner popover-inner">
          <contact-item
            v-for="liker in likesSnippet"
            :key="liker"
            avatar-type="imgSmall"
            :with-bubble="false"
            item-class="d-grid grid_auto_1fr grid-col-gap-1rem align-items-center mb-4"
            text-class="d-block font-14 fw-600 c-cloud-burst mb-3"
          />
          <span
            v-if="moreCount > 0"
            class="d-block font-14 fw-400 c-cloud-burst mt-4"
          >
            and {{ moreCount }} more
          </span>
        </div>
        <div class="tooltip-arrow popover-arrow" />
      </div>
    </div>
  </div>
</template>

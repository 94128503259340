<script>
export default {
  name: "BaseIconBubble",

  props: {
    type: {
      type: String,
      default: 'online'
    },
    iconClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div
    v-if="type === 'online'"
    class="circle w-5 h-5 p-absolute b-0 r-0 bg-primary c-wh font-8 fw-600 d-flex align-items-center justify-content-center border-white"
  ></div>
  <div
    v-else-if="type === 'icon'"
    class="circle w-9 h-9 p-absolute b--3 r--3 bg-selective-yellow c-wh font-8 fw-600 d-flex align-items-center justify-content-center"
  >
    <i :class="iconClass" />
  </div>
</template>
<script>
import BaseCarouselIndicator from "~/components/Base/BaseCarouselIndicator";
import BaseButton from "~/components/Base/BaseButton";
import CarouselItem from "~/components/Shared/ImageSlider/CarouselItem";
import {onMounted, reactive, ref} from "vue"

export default {
  name: "image-slider",

  components: {
    CarouselItem,
    BaseCarouselIndicator,
    BaseButton
  },

  props: {
    slides: {
      type: Array,
      default: () => []
    },
    imgClass: {
      type: String,
      default: 'd-block w-100 h-100 img-object-fit'
    },
    innerClass: {
      type: String,
      default: 'carousel-inner embed-responsive embed-responsive-16by9 p-relative w-100 clearfix'
    },
    innerExtraClass: {
      type: String,
      default: ''
    },
    showIndicator: {
      type: Boolean,
      default: true
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    indicatorStyle: {
      type: String,
      default: 'default'
    },
    withBorderRadius: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const slideCount = props.slides.length
    const currentIdx = ref(0)
    const lastIdx = slideCount - 1
    const direction = ref('')
    const nextBtn = ref(null)
    const prevBtn = ref(null)
    const minSwipe = 30

    const swipeState = reactive({
      mouseDown: false,
      touchStarted: false,
      touchEnded: false,
      downX: null,
      startX: null,
      distance: 0
    })


    function showNext() {
      direction.value = 'next'
      currentIdx.value = currentIdx.value === lastIdx ? 0 : currentIdx.value + 1
    }

    function showPrev() {
      direction.value = 'prev'
      currentIdx.value = currentIdx.value === 0 ? lastIdx : currentIdx.value - 1
    }

    function showSpecific(idx) {
      direction.value = idx > currentIdx.value ? 'next' : 'prev'
      currentIdx.value = idx
    }

    function getSlideSrc(idx, slide) {
      const preloadIndexes = getPreloadIndexes(currentIdx.value)
      return preloadIndexes.indexOf(idx) > -1 ? slide : ''
    }

    function getPreloadIndexes(forIdx) {
      const preloadMargin = 2
      const nextIndexes = getNextIndexes(forIdx, preloadMargin)
      const prevIndexes = getPrevIndexes(forIdx, preloadMargin)

      return nextIndexes.concat(prevIndexes).concat([forIdx])
    }

    function getNextIdx(idx) {
      return idx + 1 > (slideCount - 1) ? 0 : idx + 1
    }

    function getPrevIdx(idx) {
      return idx - 1 < 0 ? (slideCount - 1) : idx - 1
    }

    function getNextIndexes(forIdx, positions) {
      const nextIndexes = []
      let _currentIdx = forIdx

      for (let i = 0; i < positions; i++) {
        const nextIdx = _currentIdx + 1 > (slideCount - 1) ? 0 : _currentIdx + 1
        nextIndexes.push(nextIdx)
        _currentIdx = getNextIdx(_currentIdx)
      }

      return nextIndexes
    }

    function getPrevIndexes(forIdx, positions) {
      const prevIndexes = []
      let _currentIdx = forIdx

      for (let i = 0; i < positions; i++) {
        const prevIdx = _currentIdx - 1 < 0 ? slideCount - 1 : _currentIdx - 1
        prevIndexes.push(prevIdx)
        _currentIdx = getPrevIdx(_currentIdx)
      }

      return prevIndexes
    }

    function handleMouseDown(e) {
      swipeState.mouseDown = true
      swipeState.downX = e.screenX
    }

    function handleMouseUp(e) {
      swipeState.mouseDown = false
      const x = e.screenX
      const distance = x - swipeState.downX
      if (Math.abs(distance) < minSwipe) {
        return
      }
      if (distance > 0) {
        showPrev()
      } else {
        showNext()
      }
    }

    function handleTouchStart(e) {
      if (swipeState.touchStarted) {
        return
      }

      swipeState.touchStarted = true
      const touchObj = e.changedTouches[0]
      swipeState.distance = 0
      swipeState.startX = touchObj.pageX
      // e.preventDefault()
    }

    function handleTouchMove(e) {
      e.preventDefault()
    }

    function handleTouched(e) {
      if (swipeState.touchEnded) {
        return
      }
      swipeState.touchEnded = true
      const touchObj = e.changedTouches[0]
      swipeState.distance = touchObj.pageX - swipeState.startX
      swipeState.startX = touchObj.pageX
      handleSwipe()
      // e.preventDefault()
    }

    function handleSwipe() {

      if (Math.abs(swipeState.distance) < minSwipe) {
        resetSwipeState()
        return
      }

      if (swipeState.distance < 0) {
       showNext()
      } else {
        showPrev()
      }
      resetSwipeState()
    }

    function resetSwipeState() {
      swipeState.touchStarted = false
      swipeState.touchEnded = false
      swipeState.distance = 0
      swipeState.startX = null
    }

    onMounted(() => {
      const surface = document.getElementById('carousel-wrapper')
      surface.addEventListener('touchstart', handleTouchStart, false);
      surface.addEventListener('touchmove', handleTouchMove, false);
      surface.addEventListener('touchend', handleTouched, false);
    })

    return {
      slideCount,
      currentIdx,
      direction,
      prevBtn,
      nextBtn,
      showNext,
      showPrev,
      showSpecific,
      getSlideSrc,
      handleMouseUp,
      handleMouseDown
    }
  }
}
</script>

<template>
  <div
    id="carousel-wrapper"
    class="carousel border overflow-hidden p-relative d-flex align-items-center"
    :class="{ 'border-radius-xl': withBorderRadius }"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <base-carousel-indicator
      :slide-count="slideCount"
      :current-idx="currentIdx"
      @bullet-click="showSpecific"
      :indicator-style="indicatorStyle"
    />
    <div
      v-if="showIndicator"
      class="p-absolute b-8 r-8 c-wh font-18 z-index-2 fw-600 text-shadow"
    >
      <span>{{ currentIdx + 1 }}</span>/{{ slides.length }}
    </div>
      <div
        class="carousel-inner embed-responsive embed-responsive-16by9 p-relative w-100 clearfix"
        :class="innerExtraClass"
        style="overflow: visible"
      >
        <carousel-item
          v-for="(slide, idx) in slides"
          :key="idx"
          :index="idx"
          :src="getSlideSrc(idx, slide)"
          :current-index="currentIdx"
          :direction="direction"
          :img-class="imgClass"
          @swipe-left="showNext"
          @swipe-right="showPrev"
        />
      </div>
    <base-button
      v-if="showButtons"
      class="circle w-17 h-17 carousel-control-prev d-flex align-items-center justify-content-center border-0 text-center p-absolute l-8 font-30"
      role="button"
      data-slide="prev"
      @click="showPrev"
    />
    <base-button
      v-if="showButtons"
      class="circle w-17 h-17 carousel-control-next d-flex align-items-center justify-content-center border-0 text-center p-absolute r-8 font-30"
      role="button"
      data-slide="next"
      @click="showNext"
    />
  </div>
</template>

<script>

import ItemDetailsTitle from "~/components/Shared/ItemDetails/ItemDetailsTitle";
import ItemDetailsItem from "~/components/Shared/ItemDetails/ItemDetailsItem";

export default {
  name: "bike-details",

  components: {
    ItemDetailsItem,
    ItemDetailsTitle
  },

  props: {
    type: {
      type: String,
      default: 'default'
    }
  },

  setup() {
    const typeClasses = {
      default: {
        main: "d-grid grid_auto_1fr align-items-center",
        iconSection: "align-self-start d-md-none d-lg-block",
        title: "font-15 font-lg-17 mb-3 mb-md-5 mb-lg-3",
        iconWrapper: "circle w-17 h-17 border shadow d-inline-flex align-items-center justify-content-center d-xl-none d-xxl-inline-flex mr-6",
        list: "list-unstyled d-flex flex-wrap align-items-center justify-content-start mb-0 font-12 font-xxl-14 fw-600 list-separator"
      },
      myQuiver: {
        main: "d-grid grid_auto_1fr grid-col-gap-1rem my-12 mt-lg-0",
        iconSection: "",
        iconWrapper: "w-18 h-18 border-secondary bg-silver_tree-o-5 d-inline-flex align-items-center justify-content-center circle mb-4",
        title: "mb-4 font-16 font-lg-20 c-cloud-burst fw-700",
        list: "list-unstyled d-flex flex-wrap align-items-center justify-content-start mb-0 font-14 font-md-12 font-lg-14 fw-600 list-separator"
      }
    }

    return {
      typeClasses
    }
  }
}
</script>

<template>
  <div :class="typeClasses[type].main">
    <div :class="typeClasses[type].iconSection">
      <div :class="typeClasses[type].iconWrapper">
        <i class="fas fa-bicycle font-24 c-primary"></i>
      </div>
    </div>
    <div>
      <item-details-title
        :class="typeClasses[type].title"
        bike-name="Yeti Cycle"
        bike-model="SB150"
        :type="type"
      />
      <ul :class="typeClasses[type].list">
        <item-details-item
          class="c-gray-chateau mr-4 pr-4 mr-md-3 pr-md-3 mr-lg-4 pr-lg-4 p-relative"
          value="29"
          unit='"'
        />
        <item-details-item
          class="c-gray-chateau mr-4 pr-4 mr-md-3 pr-md-3 mr-lg-4 pr-lg-4 p-relative"
          value="140/130"
          unit="mm"
        />
        <item-details-item
          class="c-gray-chateau p-relative"
          value="13.2"
          unit="kg"
        />
      </ul>
    </div>
  </div>
</template>
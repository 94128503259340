<script>
import { toRefs } from "vue"

export default {
  name: "base-page-content-wrapper",

  props: {
    pageLoading: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {
    let { pageLoading: contentLoading} = toRefs(props)

    return {
      contentLoading
    }
  },
}
</script>

<template>
  <transition name="base-page">
    <div v-if="!contentLoading">
      <slot/>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center align-items-center min-h-100vh font-30" style="color: #2D9F20">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>
  </transition>
</template>

<style>
  .base-page-enter-active {
    animation: pageEnterAnimation .5s ease-in-out;
  }

  @keyframes pageEnterAnimation {
    from { opacity: 0; }
    to { opacity: 1; }
  }

</style>
<script>
import CardWrapper from "~/components/Shared/CardWrapper";
import CardHeader from "~/components/Shared/CardHeader";
import CardBody from "~/components/Shared/CardBody";
import NewsfeedReactions from "~/components/Shared/NewsFeed/NewsfeedReactions";
import NewsfeedPoster from "~/components/Shared/NewsFeed/NewsfeedPoster";
import BaseCommentInput from "~/components/Base/BaseCommentInput";
import NewsfeedComments from "~/components/Shared/NewsFeed/NewsfeedComments";

export default {
  name: "newsfeed-item",

  components: {
    NewsfeedComments,
    NewsfeedReactions,
    CardWrapper,
    CardHeader,
    CardBody,
    NewsfeedPoster,
    BaseCommentInput
  },

  props: {
    reactions: {
      type: Object,
      default: () => {}
    },
    contentWrapperClass: {
      type: String,
      default: 'px-6 px-lg-10'
    },
    hasComments: {
      type: Boolean,
      default: true
    }
  },

  setup() {
    let dummyReactions = {
      likes: [
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'},
        { firstName: 'Darrell', lastName: 'Chavez'}
      ],
      comments: 37,
      views: 159
    }

    return {
      dummyReactions
    }
  }
}
</script>

<template>
  <card-wrapper class="mb-12 border ">
    <card-header class="bg-alabaster d-flex align-items-center justify-content-between py-6 border-bottom">
      <newsfeed-poster
        post-time-date="1h"
        social-url="/img/dummy/fb_share.svg"
      />
    </card-header>
    <card-body class="py-6 py-lg-10">
      <!-- Newsfeed Item Content -->
      <slot/>
      <div class="px-6 px-lg-10">
        <hr class="border-top border-bottom-0 my-0">
        <newsfeed-reactions :reactions="dummyReactions" />
        <hr class="border-top border-bottom-0 mb-0 mt-10">
        <newsfeed-comments
          v-if="hasComments"
        />
      </div>
    </card-body>
    <base-comment-input
      placeholder-text="What's on your mind?"
      textarea-id="woym-2"
      type="footer"
      avatar-class="circle img-object-fit w-14 h-14 w-md-16 h-md-16 border-white-2x shadow_darker_10 p-relative z-index-1 mr-4"
    />
  </card-wrapper>
</template>
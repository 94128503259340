
const environment = "development",
  env = process.env,
  envObj = {
    development: {
      test: env.VUE_APP_DEV_TEST,
      apiBase: env.VUE_APP_DEV_API_BASE
    },
    production: {
      test: env.VUE_APP_PROD_TEST
    }
  }

export default envObj[environment]

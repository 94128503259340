/**
 * Function to determine if a field is invalid or not, considering validation errors & request errors from the API
 * @param field
 * @param formValidator
 * @param requestError
 * @param considerValid - can be used if a field's state should be changed to valid
 * (ie when submitting the data to reset the state before the API response comes back)
 * @returns {boolean|boolean|*}
 */
export const fieldInvalid = (field, formValidator, requestError, considerValid = false) => {
  const _hasReqError = _fieldHasRequestError(field, requestError),
    _hasValidatorErr = _fieldHasValidatorError(field, formValidator)

  return !considerValid && (_hasReqError || _hasValidatorErr)
}

export const _fieldHasValidatorError = (field, formValidator) => {
  const safeValidator = formValidator || {},
    fieldValidator = safeValidator[field] || {},
    _isInvalid = fieldValidator.$invalid,
    _isDirty = fieldValidator.$dirty

  return _isDirty && _isInvalid
}

export const _fieldHasRequestError = (field, requestError) => {
  const _safeReqErr = requestError || {}
  return (_safeReqErr[field] || []).length > 0
}

export const getFieldError = (field, formValidator, requestError) => {
  const _validationErr = _getValidatorError(field, formValidator),
    _requestErr = _getRequestError(field, requestError)

  return _validationErr || _requestErr
}

export const _getValidatorError = (field, formValidator) => {
  const _safeValidator = formValidator || {},
    _fieldValidator = _safeValidator[field] || {},
    _fieldValidatorErrors = _fieldValidator.$errors || []

  return (_fieldValidatorErrors[0] || {}).$message
}

export const _getRequestError = (field, requestError) => {
  const _safeReqErr = requestError || {},
    _fieldErrors = _safeReqErr[field] || []

  return _fieldErrors[0]
}

export default {
  fieldInvalid,
  getFieldError
}

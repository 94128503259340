<script>
import BaseImgRound from "~/components/Base/BaseImgRound";
import BaseButton from "~/components/Base/BaseButton";
export default {
  name: "newsfeed-poster",

  components: {BaseButton, BaseImgRound},

  props: {
    postTimeDate: {
      type: String,
      default: ''
    },
    socialUrl: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="d-grid grid_auto_1fr align-items-start grid-col-gap-1_5rem w-100">
    <div>
      <base-img-round />
    </div>
    <div>
      <div class="font-15 c-cloud-burst fw-600 mb-3">
        Edith Wong
      </div>
      <div class="d-flex align-items-center c-gray-chateau fw-400 font-12 justify-content-start">
        {{ postTimeDate }}
        <div class="circle w-3 h-3 bg-gray-chateau mx-4"></div>
        <img
          :src="socialUrl"
          class="w-auto max-h-6 max-w-21"
        >
      </div>
    </div>
  </div>
  <base-button
    type="button"
    class="btn-def bg-transp btn-c-blue-bayoux font-18 pa-0"
  >
    <i class="fas fa-ellipsis-v" />
  </base-button>
</template>
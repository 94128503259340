<script>
import MainLogo from "~/components/Shared/MainLogo"
import FooterCopyright from "~/components/Shared/Footer/FooterCopyright";
import FooterSocialMenu from "~/components/Shared/Footer/FooterSocialMenu";

export default {
  name: "footer-section",

  components: {
    MainLogo,
    FooterCopyright,
    FooterSocialMenu
  }
};
</script>

<template>
  <footer class="d-flex align-items-center mt-17 text-center text-md-left py-12 py-md-0 bg-wh shadow-header-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-grid grid_footer grid-row-gap-1_5rem align-items-center">
            <main-logo/>
            <div>
              <footer-copyright/>
            </div>
            <div>
              <footer-social-menu/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>


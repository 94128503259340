<script>
export default {
  name: "base-label",

  props: {
    text: {
      type: String,
      default: ''
    },
    forInput: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <label :for="forInput">
    {{ text }}
    <span class="dark-red-text" v-if="required">*</span>
  </label>
</template>

<style scoped>
  .dark-red-text {
    color: darkred;
  }
</style>

<script>
export default {
  name: "header-search",
};
</script>

<template>
  <div
    class="search_box max-w-100 max-w-lg-24 for_navbar d-flex d-lg-inline-flex align-items-center justify-content-start p-relative my-3 my-lg-0">
    <input
      type="text"
      placeholder="Search..."
      class="form-control bg-transp border-0 order-2 font-15 px-0"
    >
    <button
      type="button"
      class="btn-def bg-transp order-1 mr-4 pa-0 font-15"
    >
      <i class="fas fa-search"/>
    </button>
  </div>
</template>


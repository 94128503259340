<script>
export default {
  name: "base-link",

  props: {
    to: {
      type: String,
      default: '#'
    }
  }
}
</script>

<template>
  <a :href="to">
    <slot/>
  </a>
</template>
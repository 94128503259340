<script>
export default {
  name: "BikeDetailsTitle",

  props: {
    bikeName: {
      type: String,
      default: ''
    },
    bikeModel: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    }
  }
}
</script>

<template>
  <h4 v-if="type === 'default'">
    {{ bikeName }}
    <span class="fw-400">{{ bikeModel }}</span>
  </h4>
  <div v-else>
    {{ `${bikeName} ${bikeModel}` }}
  </div>
</template>
<script>
import BaseRatingStar from "~/components/Base/BaseRatingStar";
import { toRefs, ref } from "vue"

export default {
  name: "rating-stars",

  components: {
    BaseRatingStar
  },

  props: {
    listClass: {
      type: String,
      default: ''
    },
    rating: {
      type: Number,
      default: 0
    },
    lastItemClass: {
      type: String,
      default: ''
    },
    itemClass: {
      type: String,
      default: ''
    },
    interactive: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const { rating } = toRefs(props),
      initRating = ref(rating.value),
      maxRating = 5,
      newRating = ref(initRating.value)

    function getStarType(star) {
      let fullStars = parseInt(newRating.value),
        hasHalfStar = !(fullStars === newRating.value),
        isHalfStar = (star - fullStars === 1) && hasHalfStar,
        isFullStar = star <= fullStars

      return isFullStar ? 'full' : isHalfStar ? 'half' : 'empty'
    }

    function showNewRating(star) {
      newRating.value = star
    }

    function resetRating() {
      newRating.value = initRating.value
    }

    function setNewRating(star) {
      initRating.value = star
    }

    return {
      maxRating,
      getStarType,
      showNewRating,
      resetRating,
      setNewRating
    }
  }
}
</script>

<template>
  <div>
    <ul class="star-rating-ns d-inline-flex align-items-center justify-content-end list-unstyled mb-0">
      <base-rating-star
        v-for="(star) in maxRating"
        :key="`star-${star}`"
        :type="getStarType(star)"
        :class="star === maxRating ? lastItemClass : itemClass"
        @mouseover="showNewRating(star)"
        @mouseleave="resetRating()"
        @click="setNewRating(star)"
      />
    </ul>
  </div>
</template>
<script>

import BaseLabel from "~/components/Base/BaseLabel";
export default {
  name: 'base-input',
  components: {BaseLabel},
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Number, String],
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    classes: {
      type: String,
      default: '',
    },
    maxLength: {
      type: [String, Number],
      default: 0
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: ''
    },
    labelBefore: {
      type: Boolean,
      default: true
    },
    labelClass: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: 'form-group'
    },
    invalid: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },


}
</script>

<template>
  <div :class="wrapperClass">
    <base-label
      v-if="labelText && labelBefore"
      :text="labelText"
      :class="labelClass"
      :required="required"
    />
    <input
      :class="`${inputClass} ${ invalid ? 'is-invalid' : ''}`"
      :id="id"
      :type="type"
      :value="modelValue"
      :name="name || id"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :max-lenth="maxLength"
      :autocomplete="autocomplete"
      @input="e => $emit('update:modelValue', e.target.value)"
    >
    <base-label
      v-if="labelText && !labelBefore"
      :text="labelText"
      :class="labelClass"
    />
    <div
      v-if="invalid"
      class="invalid-feedback"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<style>

  .invalid-feedback {
    animation: shake 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>

<script>
import MainLogo from "~/components/Shared/MainLogo"
import HeaderSearch from "~/components/Shared/Header/HeaderSearch";
import HeaderMenu from "~/components/Shared/Header/HeaderMenu";
import HeaderProfileNav from "~/components/Shared/Header/HeaderProfileNav";
import BaseButton from "~/components/Base/BaseButton"

export default {
  name: 'header-section',

  components: {
    MainLogo,
    HeaderSearch,
    HeaderMenu,
    HeaderProfileNav,
    BaseButton
  },

  props: {
    page: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <header class="bg-wh shadow-header-footer">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container d-flex align-items-center justify-content-between min-h-header">
          <main-logo/>
        <base-button class="navbar-toggler" type="button" data-toggle="collapse">
          <i class="navbar-toggler-icon fas fa-bars"/>
        </base-button>
        <div class="navbar-collapse collapse show">
          <header-search/>
          <header-menu :page="page"/>
          <header-profile-nav/>
        </div>
      </div>
    </nav>
  </header>
</template>


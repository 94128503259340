<script>
import CommentSection from "~/components/Shared/Comment/CommentSection";

export default {
  name: "newsfeed-comments",

  components: {
    CommentSection
  },
}
</script>

<template>
  <div class="mt-12">
    <comment-section type="newsfeed"/>
    <div class="p-relative">
      <div class="border-left p-absolute t-0 b-0 l-10"></div>
      <comment-section class="pl-17 pl-md-19 mt-12" type="newsfeed"/>
      <comment-section class="pl-17 pl-md-19 mt-12" type="newsfeed"/>
    </div>
  </div>
</template>
<script>
import BaseIconBubble from "~/components/Base/BaseIconBubble";
export default {
  name: "base-img-round",
  components: {BaseIconBubble},
  props: {
    src: {
      type: String,
      default: '/img/dummy/woman_photo.jpg'
    },
    type: {
      type: String,
      default: 'img'
    },
    text: {
      type: String,
      default: 'd'
    },
    iconClass: {
      type: String,
      default: ''
    },
    withBubble: {
      type: Boolean,
      default: false
    },
    bubbleType: {
      type: String,
      default: ''
    },
    bubbleIconClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    }
  },

  setup() {
    let typeClasses = {
      img: 'circle img-object-fit w-16 h-16 border-white-2x shadow_darker_10',
      imgSmall: 'circle img-object-fit w-15 h-15 border-white-2x shadow_darker_10',
      text: 'circle w-14 h-14 w-md-16 h-md-16 border-white-2x shadow_darker_10 d-flex align-items-center justify-content-center c-cinnabar bg-cinnabar-o-2 font-16 font-md-20',
      icon: 'circle w-16 h-16 border-white-2x shadow_darker_10 d-flex align-items-center justify-content-center c-cinnabar bg-cinnabar-o-2 font-18'
    }

    return {
      typeClasses
    }
  }
}
</script>

<template>
  <span class="p-relative">
    <img
      v-if="type === 'img' || type === 'imgSmall'"
      :src="src"
      :class="contentClass || typeClasses[type]"
      alt="..."
    >
    <div
      v-else-if="type === 'text'"
      :class="contentClass || typeClasses[type]"
    >
      {{ text[0].toUpperCase() }}
    </div>
    <span
      v-else-if="type === 'icon'"
      :class="contentClass || typeClasses[type]"
    >
      <i :class="iconClass"/>
    </span>

    <base-icon-bubble
      v-if="withBubble"
      :type="bubbleType"
      :icon-class="bubbleIconClass"
    />
  </span>
</template>
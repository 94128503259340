<script>
export default {
  name: "base-views-count",

  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>

<template>
  <div class="border-radius border shadow c-regent-grey py-6 py-md-3 px-6 px-md-4 font-14 font-md-12 fw-600">
    <i class="fas fa-eye mr-3" />
    <span>
      {{ ` ${value}` }} <span class="d-none d-md-inline">Views</span>
    </span>
  </div>
</template>